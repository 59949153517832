import { render, staticRenderFns } from "./Renderer3D.vue?vue&type=template&id=510dbe8e&scoped=true"
import script from "./Renderer3D.vue?vue&type=script&lang=js"
export * from "./Renderer3D.vue?vue&type=script&lang=js"
import style0 from "./Renderer3D.vue?vue&type=style&index=0&id=510dbe8e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../BuildingCatalog/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "510dbe8e",
  null
  
)

export default component.exports