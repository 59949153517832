const enums = {}

enums.allRenovations = [
        {
            name: 'roofIn',
            type: 'roof',
        },
        {
            name: 'atticFloor',
            type: 'attic',
        },
        {
            name: 'wallExt',
            type: 'wall',
        },
        {
            name: 'windowsDouble',
            type: 'window',
        },
        {
            name: 'condGas',
            type: 'heating',
        },
        {
            name: 'heatPump',
            type: 'heating',
        },
        {
            name: 'wallCav',
            type: 'wall',
        },
        {
            name: 'unitPv',
            type: 'solar_panel',
        },
        {
            name: 'roofExt',
            type: 'roof',
        },
]

enums.renovationWarningRules = [
    // x: a selected renovation type
    // y: an array of renovation types that are available but not selected
    {
        x: 'window',
        y: ['roof', 'wall'],
    },
    {
        x: 'solar_panel',
        y: ['roof'],
    },
    {
        x: 'heating',
        y: ['roof', 'window', 'wall'],
    },
]

enums.epc_mapping = {
    Wallonia: {
        'A++': 0,
        'A+': 1,
        A: 46,
        B: 86,
        C: 171,
        D: 256,
        E: 341,
        F: 426,
        G: 511,
    },
    'BE-WAL': {
        'A++': 0,
        'A+': 1,
        A: 46,
        B: 86,
        C: 171,
        D: 256,
        E: 341,
        F: 426,
        G: 511,
    },
    Flanders: {
        'A+': -1,
        A: 0,
        B: 101,
        C: 201,
        D: 301,
        E: 401,
        F: 501,
    },
    'BE-VLG': {
        'A+': -1,
        A: 0,
        B: 101,
        C: 201,
        D: 301,
        E: 401,
        F: 501,
    },
    Brussels: {
        A: 45,
        B: 46,
        C: 96,
        D: 151,
        E: 211,
        F: 276,
        G: 346,
    },
    'BE-BRU': {
        A: 45,
        B: 46,
        C: 96,
        D: 151,
        E: 211,
        F: 276,
        G: 346,
    }
}

enums.consumptions = {
    canonical_unit: {
        Brussels: {
            electricity: {
                min: 2153,
                med: 5284,
                max: 10960,
            },
            electricity_heat: {
                min: 3153,
                med: 7884,
                max: 17560,
            },
            gas: {
                min: 595,
                med: 1459,
                max: 3027,
            },
            oil: {
                min: 457,
                med: 1121,
                max: 2325,
            },
            wood: {
                min: 1420,
                med: 2557,
                max: 4048,
            },
            pellets: {
                min: 1380,
                med: 2483,
                max: 3932,
            },
            coal: {
                min: 768,
                med: 1382,
                max: 2188,
            },
            district_heating: {
                min: 5500,
                med: 13500,
                max: 28000,
            },
        },
        Flanders: {
            electricity: {
                min: 8695,
                med: 15651,
                max: 24781,
            },
            electricity_heat: {
                min: 10295,
                med: 19251,
                max: 32381,
            },
            gas: {
                min: 1081,
                med: 1946,
                max: 3081,
            },
            oil: {
                min: 1205,
                med: 2169,
                max: 3434,
            },
            wood: {
                min: 1420,
                med: 2557,
                max: 4048,
            },
            pellets: {
                min: 1380,
                med: 2483,
                max: 3932,
            },
            coal: {
                min: 768,
                med: 1382,
                max: 2188,
            },
            district_heating: {
                min: 10000,
                med: 18000,
                max: 28500,
            },
        },
        Wallonia: {
            electricity: {
                min: 6029,
                med: 11423,
                max: 18404,
            },
            electricity_heat: {
                min: 7629,
                med: 15223,
                max: 27604,
            },
            gas: {
                min: 1027,
                med: 1946,
                max: 2900,
            },
            oil: {
                min: 1067,
                med: 2022,
                max: 3258,
            },
            wood: {
                min: 1304,
                med: 2471,
                max: 3981,
            },
            pellets: {
                min: 1267,
                med: 2400,
                max: 3866,
            },
            coal: {
                min: 705,
                med: 1336,
                max: 2152,
            },
            district_heating: {
                min: 9500,
                med: 18000,
                max: 29000,
            },
        },
    },
    kwh: {
        Brussels: {
            electricity: {
                min: 2153,
                med: 5284,
                max: 10960,
            },
            electricity_heat: {
                min: 1000,
                med: 2600,
                max: 6600,
            },
            gas: {
                min: 5500,
                med: 13500,
                max: 28000,
            },
            oil: {
                min: 4603,
                med: 11297,
                max: 23432,
            },
            wood: {
                min: 6250,
                med: 11250,
                max: 17812,
            },
            pellets: {
                min: 6250,
                med: 11250,
                max: 17812,
            },
            coal: {
                min: 6250,
                med: 11250,
                max: 17812,
            },
            district_heating: {
                min: 5500,
                med: 13500,
                max: 28000,
            },
        },
        Flanders: {
            electricity: {
                min: 8695,
                med: 15651,
                max: 24781,
            },
            electricity_heat: {
                min: 1600,
                med: 3600,
                max: 7600,
            },
            gas: {
                min: 10000,
                med: 18000,
                max: 28500,
            },
            oil: {
                min: 12146,
                med: 21862,
                max: 14615,
            },
            wood: {
                min: 6250,
                med: 11250,
                max: 17812,
            },
            pellets: {
                min: 6250,
                med: 11250,
                max: 17812,
            },
            coal: {
                min: 6250,
                med: 11250,
                max: 17812,
            },
            district_heating: {
                min: 10000,
                med: 18000,
                max: 28500,
            },
        },
        Wallonia: {
            electricity: {
                min: 6029,
                med: 11423,
                max: 18404,
            },
            electricity_heat: {
                min: 1600,
                med: 3800,
                max: 9200,
            },
            gas: {
                min: 9500,
                med: 18000,
                max: 29000,
            },
            oil: {
                min: 10757,
                med: 20383,
                max: 32839,
            },
            wood: {
                min: 5738,
                med: 10871,
                max: 17515,
            },
            pellets: {
                min: 5738,
                med: 10871,
                max: 17515,
            },
            coal: {
                min: 5738,
                med: 10871,
                max: 17515,
            },
            district_heating: {
                min: 9500,
                med: 18000,
                max: 29000,
            },
        },
    },
}

export default enums